import axios from "axios";

const BASE_URL = "https://api-expo2022.sanita2030.it/";
//const BASE_URL = "/api";

export const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

export const getCSRFCookie = () => {
  return new Promise((resolve) => {
    api.get("sanctum/csrf-cookie").then(resolve);
  });
};
